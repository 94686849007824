import React from "react"

export default ({name, value, submitting, disabled, onChange}) => (
  <textarea
    className="form-control"
    rows="4"
    id={name}
    name={name}
    value={value || ''}
    onChange={onChange}
    disabled={submitting || disabled ? 'disabled' : false}
  ></textarea>
)
