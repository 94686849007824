import React, { useState, useEffect } from "react"
import { Link } from "gatsby"

import Layout   from "../../../components/layout"
import SEO      from "../../../components/seo"
import OrgInfo  from "../../../components/common/org_info"
import Loading  from "../../../components/common/loading"
import RowInputEdit from "../../../components/form/row_input_edit"
import MembersTable from "../../../components/accounts/members_table"

import { doAuthenticatedPost } from "../../../actions/_methods"
import { SuccessAlert, ErrorAlert } from "../../../actions/notification"

const ViewAccount = ({uuid}) => {
  const [data, setData] = useState({})
  const [original_data, setOriginalData] = useState({})
  const [members, setMembers] = useState([])
  const [loading, setLoading] = useState(true)
  const [loading_member, setLoadingMember] = useState(true)
  const [submitting, setSubmitting] = useState(false)
  const [editing, setEditing] = useState(false)
  const [organization, setOrganization] = useState({name: ""})

  useEffect(() => {
    doAuthenticatedPost("/admin/accounts/view", {account_uuid: uuid}, {
      success:  (res) => {
        setData(res.account)
        setOriginalData(res.account)
        setOrganization(res.organization)
        setLoading(false)

        doAuthenticatedPost("/admin/members/list", {account_uuid: uuid}, {
          success:  (res) => {
            setMembers(res.members)
            setLoadingMember(false)
          },
          error:    (res) => ErrorAlert(),
          catch:    (res) => ErrorAlert()
        })
      },
      error:    (res) => ErrorAlert(),
      catch:    (res) => ErrorAlert()
    })
  }, [])

  const onCancel = (e) => {
    e.preventDefault()
    setData(original_data)
    setEditing(false)
  }

  const onSubmit = (e) => {
    e.preventDefault()
    setSubmitting(true)
    doAuthenticatedPost("/admin/accounts/update", {...data, account_uuid: uuid}, {
      success:  (res) => onSuccess(res),
      error:    (res) => onError(res),
      catch:    (res) => onError(res)
    })
  }

  const onSuccess = (res) => {
    SuccessAlert("Yay, account updated 🎉")
    setData(res.account)
    setOriginalData(res.account)
    setSubmitting(false)
    setEditing(false)
  }

  const onError = () => {
    ErrorAlert()
    setSubmitting(false)
  }

  const onChange = (e) => {
    let new_data = {...data}
    new_data[e.target.name] = e.target.value
    setData(new_data)
  }

  const row_input = (name, label, type) => <RowInputEdit
    type={type ? type : 'text'}
    props={{
      name,
      label,
      submitting,
      onChange,
      value: data[name] || '',
      disabled: !editing
    }}
  />

  const edit_actions = () => {
    if(editing) {
      return(<>
        <button className="btn btn-sm btn-primary" onClick={onSubmit}>Save Changes</button>
        <button className="btn btn-sm btn-secondary mx-2" onClick={onCancel}>Cancel</button>
      </>)
    } else {
      return <button className="btn btn-sm btn-warning" onClick={()=>setEditing(true)}>Edit Account Info</button>
    }
  }

  const membersSection = () => {
    if(loading_member) {
      return <Loading />
    } else {
      return(<MembersTable records={members} account_uuid={uuid} />)
    }
  }

  if(loading) {
    return <Layout top_panel={<OrgInfo organization={organization} />}><Loading /></Layout>
  } else {
  return(<Layout top_panel={<OrgInfo organization={organization} />}>
    <SEO title="View Account" />
    <div className="px-5 mt-3">
      <h4 className="text-secondary">View Account</h4>
      <div className="card p-5 text-dark bg-light">
        <div className="d-flex">
          <div style={{minWidth: "50%"}}>
            {row_input("reference_number", "Reference #")}
            {row_input("name", "Account Name")}
            {row_input("description", "Description", "textarea")}
            <hr className="my-5" />
            {row_input("primary_mobile", "Primary Mobile")}
            {row_input("secondary_mobile", "Secondary Mobile")}
            {row_input("phone_number", "Phone Number")}
            {row_input("email", "Email", "email")}
            <div className="row mb-3">
              <div className="col-auto w-25"></div>
              <div className="col-auto w-75">{edit_actions()}</div>
            </div>
          </div>

          <div className="d-flex justify-content-center w-50">
            <div
              className="border rounded-3 d-flex flex-column justify-content-center text-center bg-white"
              style={{height: "200px", width: "200px"}}>
              photo here
            </div>
          </div>
        </div>

        <div>
          <hr/>
          <h4 className="text-secondary mb-2">Account Members</h4>
          {membersSection()}
        </div>
        <div className="text-center mt-5">
          <Link to="/app/accounts" className="btn btn-secondary btn-lg mx-2 px-5">Close</Link>
        </div>
      </div>
    </div>
  </Layout>)
  }
}

export default ViewAccount