import React from "react"

export default ({name, value, submitting, disabled, onChange}) => {

  const onChangeLocal = (e) => {
    const target = {...e.target}
    target.name = name
    target.value = (e.target.value === 'no' ? false : true)
    e.target = target
    onChange(e)
  }

  const selectedValue = (val) => {
    return val === false ? 'no' : 'yes'
  }

  return(
    <select
      className="form-select"
      id={name}
      name={name}
      value={selectedValue(value)}
      onChange={onChangeLocal}
      disabled={submitting || disabled ? 'disabled' : false}
    >
      <option value="yes">Yes</option>
      <option value="no">No</option>
    </select>
  )
}