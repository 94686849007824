import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import {
  hasAuthToken,
  removeAuthToken
} from '../../../actions/_auth';

const Header = ({ siteTitle }) => {

  const onSignOut = (e) => {
    e.preventDefault();
    removeAuthToken();
    const windowGlobal = typeof window !== 'undefined' && window
    windowGlobal.location = '/'
  }

  const loggedElem = (
    <>
      <ul className="navbar-nav me-auto mb-2 mb-lg-0">
        <li className="nav-item">
          <Link to="/app" className="nav-link active">Dashboard</Link>
        </li>
        <li className="nav-item">
          <span className="nav-link">Link</span>
        </li>
      </ul>
      <div className="d-flex">
        <button className="btn" onClick={onSignOut}>Signout</button>
      </div>
    </>
  );

  const unloggedElem = (
    <>
      <ul className="navbar-nav me-auto mb-2 mb-lg-0">
        <li className="nav-item">
          <Link to="/app" className="nav-link active"></Link>
        </li>
        <li className="nav-item">
          <span className="nav-link">Link</span>
        </li>
      </ul>
      <div className="d-flex">
        Login
      </div>
    </>
  );

  return(
    <header>
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid">
          <Link to="/" className="navbar-brand">{siteTitle}</Link>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            { hasAuthToken() ? loggedElem : unloggedElem }
          </div>
        </div>
      </nav>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
