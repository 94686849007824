import React, { useState } from "react"
import { Link } from "gatsby"

import Layout   from "../../../../components/layout"
import SEO      from "../../../../components/seo"
import OrgInfo  from "../../../../components/common/org_info"
import RowInput from "../../../../components/form/row_input"

import { doAuthenticatedPost } from "../../../../actions/_methods"
import { ErrorAlert, SuccessAlert } from "../../../../actions/notification"

const member_types = [
  {value: "homeowner",            label: "Homeowner"},
  {value: "resident",             label: "Resident"},
  {value: "helper_resident",      label: "Helper Resident"},
  {value: "helper_non_resident",  label: "Helper Non-Resident"},
  {value: "vehicle",              label: "Vehicle"},
  {value: "pet",                  label: "Pet"},
  {value: "other",                label: "Other"},
]

const statuses = [
  {value: "active",   label: "Active"},
  {value: "inactive", label: "Inactive"},
]

const default_data = {
  member_type: "homeowner",
}

const AddMember = ({account_uuid}) => {
  const [data, setData] = useState(default_data)
  const [submitting, setSubmitting] = useState(false)
  const [organization, setOrganization] = useState({name: ""})

  const onSubmit = (e) => {
    e.preventDefault()
    setSubmitting(true)
    doAuthenticatedPost("/admin/members/create", {...data, account_uuid: account_uuid}, {
      success:  (res) => onSuccess(res),
      error:    (res) => onError(res),
      catch:    (res) => onError(res)
    })
  }

  const onSuccess = (res) => {
    SuccessAlert("Yay, new member added 🎉")
    setData(default_data)
    setSubmitting(false)
  }

  const onError = (res) => {
    console.log(res)
    ErrorAlert()
    setSubmitting(false)
  }

  const onChange = (e) => {
    let new_data = {...data}
    new_data[e.target.name] = e.target.value
    setData(new_data)
  }

  const row_input = (name, label, type, options=[]) => <RowInput
    type={type ? type : 'text'}
    props={{
      name,
      label,
      submitting,
      onChange,
      value: (type === 'true_false' ? (data[name] === false ? false : true) : (data[name] || '')),
      options: options
    }}
  />

  return(<Layout>
    <SEO title="Add Member" />
    <div className="px-5 mt-3">
      <h4 className="text-secondary">Add Member</h4>
      <div className="card p-5 text-dark bg-light">
        <div className="d-flex justify-content-between">
          <div style={{minWidth: "50%"}}>
            {row_input("member_type", "Member Type", "dropdown", member_types)}
            {row_input("first_name", "First Name")}
            {row_input("last_name", "Last Name")}
            {row_input("description", "Description", "textarea")}
            {row_input("primary_mobile", "Primary Mobile")}
            {row_input("secondary_mobile", "Secondary Mobile")}
            {row_input("phone_number", "Phone Number")}
            {row_input("email", "Email", "email")}
          </div>
          <div style={{minWidth: "45%"}}>
            {row_input("rfid_card", "RFID Card")}
            {row_input("rfid_sticker", "RFID Sticker")}
            {row_input("qr_code", "QR Code")}
            {row_input("bar_code", "Barcode")}
            <hr className="my-5" />
            {row_input("status", "Member Status", "dropdown", statuses)}
            {row_input("loggable", "App Login?", "true_false")}
            {row_input("general_notifiyable", "General Notification?", "true_false")}
            {row_input("gate_notifiyable", "Gate Notification?", "true_false")}
          </div>
        </div>

        <div className="text-center mt-5">
          <Link to="/app/accounts/members/view" className="btn btn-primary btn-lg mx-2 px-5" onClick={onSubmit}>Save Member</Link>
          <Link to={`/app/accounts/${account_uuid}`} className="btn btn-secondary btn-lg mx-2 px-5">Cancel</Link>
        </div>
      </div>
    </div>
  </Layout>)
}

export default AddMember
