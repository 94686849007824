import React from "react"
import Label from "./input_label"
import InputText from "./input_text"
import Textarea from "./input_textarea"
import Dropdown from "./input_dropdown"
import TrueFalse from "./input_true_false"

export default ({type: type, props: {name, value, label, submitting, onChange, options}}) => {
  let input_text  = <InputText name={name} value={value || ''} submitting={submitting} onChange={onChange} />
  let input_email = <InputText type="email" name={name} value={value || ''} submitting={submitting} onChange={onChange} />
  let textarea    = <Textarea name={name} value={value || ''} submitting={submitting} onChange={onChange} />
  let dropdown    = <Dropdown name={name} value={value || ''} options={options} submitting={submitting} onChange={onChange} />
  let true_false  = <TrueFalse name={name} value={value} submitting={submitting} onChange={onChange} />

  const formElement = (type) => {
    if(type === 'textarea') {
      return textarea
    } else if(type === 'email') {
      return input_email
    } else if(type === 'dropdown') {
      return dropdown
    } else if(type === 'true_false') {
      return true_false
    } else {
      return input_text
    }
  }

  return(
  <div className="row mb-3">
    <div className="col-auto w-25">
      <Label name={name} label={label} />
    </div>
    <div className="col-auto w-75">
      {formElement(type)}
    </div>
  </div>
  )
}
