import React from "react"

export default ({name, type, value, submitting, disabled, onChange}) => (
  <input
    type={type ? type : 'text'}
    className="form-control"
    id={name}
    name={name}
    value={value || ''}
    onChange={onChange}
    disabled={submitting || disabled ? 'disabled' : false}
  />
)
