import React from 'react';
import { Link } from 'gatsby';

export default ({summary}) => {
  return(
    <div className="card text-center text-dark bg-light" style={{width: "20rem"}}>
      <div className="card-body d-flex flex-column">
        <h1 className="fs-1 fw-bold mb-0">{summary}</h1>
        <span>admins</span>
        {/* <Link to="" className="btn btn-md btn-primary mt-3">View Admins</Link>
        <Link to="" className="btn btn-md btn-warning mt-2">Add Admin</Link> */}
      </div>
    </div>
  )
}