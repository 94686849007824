import React, { useState } from "react"
import { Link } from "gatsby"

import Layout   from "../../../components/layout"
import SEO      from "../../../components/seo"
import OrgInfo  from "../../../components/common/org_info"
import RowInput from "../../../components/form/row_input"

import { doAuthenticatedPost } from "../../../actions/_methods"
import { ErrorAlert, SuccessAlert } from "../../../actions/notification"

const AddAccount = () => {
  const [data, setData] = useState({})
  const [submitting, setSubmitting] = useState(false)
  const [organization, setOrganization] = useState({name: ""})

  const onSubmit = (e) => {
    e.preventDefault()
    setSubmitting(true)
    doAuthenticatedPost("/admin/accounts/create", data, {
      success:  (res) => onSuccess(res),
      error:    (res) => onError(res),
      catch:    (res) => onError(res)
    })
  }

  const onSuccess = (res) => {
    SuccessAlert("Yay, new account added 🎉")
    setData({})
    setSubmitting(false)
  }

  const onError = () => {
    ErrorAlert()
    setSubmitting(false)
  }

  const onChange = (e) => {
    let new_data = {...data}
    new_data[e.target.name] = e.target.value
    setData(new_data)
  }

  const row_input = (name, label, type) => <RowInput
    type={type ? type : 'text'}
    props={{
      name,
      label,
      submitting,
      onChange,
      value: data[name] || ''
    }}
  />

  return(
    <Layout top_panel={<OrgInfo organization={organization} />}>
      <SEO title="Add Account" />
      <div className="px-5 mt-3">
        <h4 className="text-secondary">Add Account</h4>
        <div className="card p-5 text-dark bg-light">
          <div className="d-flex">
            <div style={{minWidth: "50%"}}>
              {row_input("reference_number", "Reference #")}
              {row_input("name", "Account Name")}
              {row_input("description", "Description", "textarea")}
              <hr className="my-5" />
              {row_input("primary_mobile", "Primary Mobile")}
              {row_input("secondary_mobile", "Secondary Mobile")}
              {row_input("phone_number", "Phone Number")}
              {row_input("email", "Email", "email")}
            </div>
            <div className="d-flex justify-content-center w-50">
              <div
                className="border rounded-3 d-flex flex-column justify-content-center text-center bg-white"
                style={{height: "200px", width: "200px"}}>
                upload photo
              </div>
            </div>
          </div>

          <div className="text-center mt-5">
            <button className="btn btn-primary btn-lg mx-2 px-5" onClick={onSubmit}>Save Account</button>
            <Link to="/app/accounts" className="btn btn-secondary btn-lg mx-2 px-5">cancel</Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default AddAccount
