import React, { useState, useEffect } from "react"
import { Link } from "gatsby"

import Layout from "../../../../components/layout"
import SEO from "../../../../components/seo"
import OrgInfo  from "../../../../components/common/org_info"
import Loading  from "../../../../components/common/loading"
import RowInputEdit from "../../../../components/form/row_input_edit"

import { doAuthenticatedPost } from "../../../../actions/_methods"
import { SuccessAlert, ErrorAlert } from "../../../../actions/notification"

const member_types = [
  {value: "homeowner",            label: "Homeowner"},
  {value: "resident",             label: "Resident"},
  {value: "helper_resident",      label: "Helper Resident"},
  {value: "helper_non_resident",  label: "Helper Non-Resident"},
  {value: "vehicle",              label: "Vehicle"},
  {value: "pet",                  label: "Pet"},
  {value: "other",                label: "Other"},
]

const statuses = [
  {value: "active",   label: "Active"},
  {value: "inactive", label: "Inactive"},
]

const ViewMember = ({account_uuid, uuid}) => {
  const [data, setData] = useState({})
  const [original_data, setOriginalData] = useState({})
  const [loading, setLoading] = useState(true)
  const [submitting, setSubmitting] = useState(false)
  const [editing, setEditing] = useState(false)
  const [organization, setOrganization] = useState({name: ""})

  useEffect(() => {
    doAuthenticatedPost("/admin/members/view", {account_uuid: account_uuid, member_uuid: uuid}, {
      success:  (res) => {
        setData(res.member)
        setOriginalData(res.member)
        setOrganization(res.organization)
        setLoading(false)
      },
      error:    (res) => ErrorAlert(),
      catch:    (res) => ErrorAlert()
    })
  }, [])

  const onCancel = (e) => {
    e.preventDefault()
    setData(original_data)
    setEditing(false)
  }

  const onSubmit = (e) => {
    e.preventDefault()
    setSubmitting(true)
    doAuthenticatedPost("/admin/members/update", {...data, account_uuid, member_uuid: uuid}, {
      success:  (res) => onSuccess(res),
      error:    (res) => onError(res),
      catch:    (res) => onError(res)
    })
  }

  const onSuccess = (res) => {
    SuccessAlert("Yay, account updated 🎉")
    setData(res.member)
    setOriginalData(res.member)
    setSubmitting(false)
    setEditing(false)
  }

  const onError = () => {
    ErrorAlert()
    setSubmitting(false)
  }

  const onChange = (e) => {
    let new_data = {...data}
    new_data[e.target.name] = e.target.value
    setData(new_data)
  }

  const row_input = (name, label, type, options=[]) => <RowInputEdit
    type={type ? type : 'text'}
    props={{
      name,
      label,
      submitting,
      onChange,
      value: (type === 'true_false' ? (data[name] === false ? false : true) : (data[name] || '')),
      options: options,
      disabled: !editing
    }}
  />

  const edit_actions = () => {
    if(editing) {
      return(<>
        <button className="btn btn-sm btn-primary" onClick={onSubmit}>Save Changes</button>
        <button className="btn btn-sm btn-secondary mx-2" onClick={onCancel}>Cancel</button>
      </>)
    } else {
      return <button className="btn btn-sm btn-warning" onClick={()=>setEditing(true)}>Edit Member Info</button>
    }
  }

  if(loading) {
    return <Layout top_panel={<OrgInfo organization={organization} />}><Loading /></Layout>
  } else {
  return(<Layout top_panel={<OrgInfo organization={organization} />}>
    <SEO title="Add Member" />
    <div className="px-5 mt-3">
      <h2>View Member</h2>
      <div className="card p-5 text-dark bg-light">
        <div className="d-flex justify-content-between">
          <div style={{minWidth: "50%"}}>
            {row_input("member_type", "Member Type", "dropdown", member_types)}
            {row_input("first_name", "First Name")}
            {row_input("last_name", "Last Name")}
            {row_input("description", "Description", "textarea")}
            {row_input("primary_mobile", "Primary Mobile")}
            {row_input("secondary_mobile", "Secondary Mobile")}
            {row_input("phone_number", "Phone Number")}
            {row_input("email", "Email", "email")}
            <div className="row mb-3">
              <div className="col-auto w-25"></div>
              <div className="col-auto w-75">{edit_actions()}</div>
            </div>
          </div>

          <div style={{minWidth: "45%"}}>
            {row_input("rfid_card", "RFID Card")}
            {row_input("rfid_sticker", "RFID Sticker")}
            {row_input("qr_code", "QR Code")}
            {row_input("bar_code", "Barcode")}
            <hr className="my-5" />
            {row_input("status", "Member Status", "dropdown", statuses)}
            {row_input("loggable", "App Login?", "true_false")}
            {row_input("general_notifiyable", "General Notification?", "true_false")}
            {row_input("gate_notifiyable", "Gate Notification?", "true_false")}
          </div>
        </div>

        <div className="text-center mt-5">
          <Link to={`/app/accounts/${account_uuid}`} class="btn btn-secondary btn-lg mx-2 px-5">Back to Account</Link>
        </div>
      </div>
    </div>
  </Layout>)
  }
}

export default ViewMember
