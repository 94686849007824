import React, { useEffect, useState } from "react"
import { Link } from "gatsby"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import OrgInfo from "../../../components/common/org_info"
import Table from "../../../components/accounts/table"
import Loading from "../../../components/common/loading"

import { doAuthenticatedPost } from "../../../actions/_methods"
import { ErrorAlert } from "../../../actions/notification"

const ListAccount = () => {
  const [loading, setLoading] = useState(true)
  const [records, setRecords] = useState([])
  const [organization, setOrganization] = useState({})

  useEffect(() => {
    doAuthenticatedPost("/admin/accounts/list", {}, {
      success:  (res) => {
        setRecords(res.accounts)
        setOrganization(res.organization)
        setLoading(false)
      },
      error:    (res) => ErrorAlert(),
      catch:    (res) => ErrorAlert()
    })
  }, [])

  return(
    <Layout top_panel={<OrgInfo organization={organization} />}>
      <SEO title="All Accounts" />
      <div className="px-5 mt-3">
        <div className="d-flex justify-content-between mb-2">
          <h4 className="text-secondary">Accounts</h4>
          <Link to="/app/accounts/add" className="btn btn-sm btn-warning">Add New Account</Link>
        </div>
        {loading ? <Loading /> : <Table records={records} />}
      </div>
    </Layout>
  )
}

export default ListAccount