import React from "react"

export default ({name, options, value, submitting, disabled, onChange}) => (
  <select
    className="form-select"
    id={name}
    name={name}
    value={value || ''}
    onChange={onChange}
    disabled={submitting || disabled ? 'disabled' : false}
  >
    {options.map(e => {
      return <option value={e.value ? e.value : e.label}>{e.label}</option>
    })}
  </select>
)
