import React from "react"
import { Link } from "gatsby"

const MembersTable = ({records, account_uuid}) => {

  const table_head = <>
    <thead className="table-dark">
      <tr>
        <th>Full name</th>
        <th>Member Type</th>
        <th>Mobile #</th>
        <th>Status</th>
        <th>Accesses</th>
      </tr>
    </thead>
  </>

  const passCodeRow = (record, key, label) => {
    if(record[key]) {
      return <li>{label}: {record[key]}</li>
    } else {
      return null
    }
  }

  const passCodes = (record) => {
    let codes = []
    codes.push(passCodeRow(record, 'rfid_card',     'RFID Card'))
    codes.push(passCodeRow(record, 'rfid_sticker',  'RFID Sticker'))
    codes.push(passCodeRow(record, 'qr_code',       'QR Code'))
    codes.push(passCodeRow(record, 'bar_code',      'Bar Code'))
    codes = codes.filter(e=>e)

    if(codes.length > 0) {
      return(<ul>{codes.map(e=>e)}</ul>)
    } else {
      return null
    }
  }

  const tableRow = (record) => {
    return(
      <tr>
        <td><Link to={`/app/accounts/${account_uuid}/members/${record.uuid}`}>{record.last_name} {record.first_name}</Link></td>
        <td>{record.type}</td>
        <td>{record.primary_mobile}</td>
        <td>{record.status}</td>
        <td>{passCodes(record)}</td>
      </tr>
    )
  }

  const table_body = <>
    <tbody>
      {records.map((record)=>tableRow(record))}
    </tbody>
  </>

  const table_foot = <>
    <tfoot>
      <tr>
        <td colSpan="5">
          <div className="d-flex justify-content-between">
            <div>
              <Link to={`/app/accounts/${account_uuid}/members/add`} className="btn btn-sm btn-warning">Add Member</Link>
            </div>
          </div>
        </td>
      </tr>
    </tfoot>
  </>

  return(<>
    <table className="table table-bordered table-striped">
      {table_head}
      {table_body}
      {table_foot}
    </table>
  </>)

}

export default MembersTable