import React, { useEffect, useState } from "react";
import { Router } from "@reach/router";
import { hasAuthToken } from '../../actions/_auth';

import Dashboard    from "./dashboard/Main";
import Accounts     from "./accounts/list"
import AccountAdd   from "./accounts/add"
import AccountView  from "./accounts/view"
import MemberAdd    from "./accounts/members/add"
import MemberView   from "./accounts/members/view"

export default () => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if(hasAuthToken() === false) {
      const windowGlobal = typeof window !== 'undefined' && window
      windowGlobal.location = '/'
    } else {
      setLoaded(true);
    }
  }, [])

  if(loaded) {
    return(
      <Router basepath="app">
        <AccountAdd path="/accounts/add" />
        <MemberAdd path="/accounts/:account_uuid/members/add" />
        <MemberView path="/accounts/:account_uuid/members/:uuid" />
        <AccountView path="/accounts/:uuid" />
        <Accounts path="/accounts" />
        <Dashboard path="/" />
      </Router>
    )
  } else {
    return null
  }
}
