import React from "react"
import { Link } from "gatsby"

const AccountsTable = ({records}) => {

  const table_head = <>
    <thead className="table-dark">
      <tr>
        <th>Reference #</th>
        <th>Account Name</th>
        <th>Description</th>
        <th></th>
      </tr>
    </thead>
  </>

  const table_body = <>
    <tbody>
      {records.map((record)=>{
        return(<tr>
          <td>{record.reference_number}</td>
          <td>{record.name}</td>
          <td>{record.description}</td>
          <td>
            <Link to={`/app/accounts/${record.uuid}`}>manage</Link>
          </td>
        </tr>)
      })}
    </tbody>
  </>

  const table_foot = <>
    <tfoot>
      <tr>
        <td colSpan="4">
          <ul className="pagination justify-content-end mb-0">
            <li className="page-item disabled">
              <a className="page-link" href="#" tabindex="-1" aria-disabled="true">Previous</a>
            </li>
            <li className="page-item"><a className="page-link" href="#">1</a></li>
            <li className="page-item"><a className="page-link" href="#">2</a></li>
            <li className="page-item"><a className="page-link" href="#">3</a></li>
            <li className="page-item">
              <a className="page-link" href="#">Next</a>
            </li>
          </ul>
        </td>
      </tr>
    </tfoot>
  </>

  return(<>
    <table className="table table-bordered table-striped">
      {table_head}
      {table_body}
      {table_foot}
    </table>
  </>)

}

export default AccountsTable