import React, { useEffect, useState } from "react";
import SEO from "../../../components/seo";
import Loading from '../../../components/common/loading';
import Layout from '../common/layout';
import Accounts from './_Accounts';
import Members from './_Members';
import Admins from './_Admins';
import { doAuthenticatedPost } from '../../../actions/_methods';

const thousandsSeparators = (num) => {
  let num_parts = (num || '').toString().split(".");
  num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return num_parts.join(".");
};

export default () => {
  const [loading, setLoading] = useState(true);
  const [organization, setOrganization] = useState({});
  const [summary, setSummary] = useState({});

  const onSuccess = (res) => {
    setOrganization(res.organization);
    setSummary(res.summary);
    setLoading(false);
  };

  const onError = (res) => {
    setLoading(false);
    console.log(res);
  };

  useEffect(()=>{
    setLoading(true);
    doAuthenticatedPost("/admin/dashboard", {}, {
      success:  (res) => onSuccess(res),
      error:    (res) => onError(res),
      catch:    (res) => onError(res)
    });
  }, []);

  return(
    <Layout>
      <SEO title={`${organization.name || ''} - Dashboard`} />
      {loading && <Loading />}
      {!loading &&
        <div className="d-flex justify-content-evenly mt-5">
          <Accounts summary={thousandsSeparators(summary.accounts)} />
          <Members summary={thousandsSeparators(summary.members)} />
          <Admins summary={thousandsSeparators(summary.admins)} />
        </div>
      }
    </Layout>
  )
}
