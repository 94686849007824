import React from "react"

const Loading = () => {

  return(<>
    <div className="text-center border p-4 mt-5 mx-5">
      loading...
    </div>
  </>)

}

export default Loading