import { toast } from 'react-toastify'

export const ErrorAlert = (message=null) => {
  toast.error(message ? message : "Oops... Something is wrong, \nplease try again 😞", {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
}

export const SuccessAlert = (message=null) => {
  toast.success(message ? message : "Yay, that was a success! 🎉", {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
}